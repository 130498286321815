import { InputHTMLAttributes, memo } from 'react';

type InputProps = {
  variant?: 'minimal';
  size?: 'normal' | 'small' | 'large';
  label?: string;
  // The following are required properties.
  type: InputHTMLAttributes<HTMLInputElement>['type'];
  onChange: InputHTMLAttributes<HTMLInputElement>['onChange'];
} & Omit<InputHTMLAttributes<HTMLInputElement>, 'size'>;

function Input(props: InputProps) {
  return <div className='relative w-full'>
    {props.label && <label
      htmlFor={props.id}
      className="block text-xs font-medium text-gray-500 mb-2 pl-1"
    >
      {props.label}
    </label>}
    <input
      autoFocus={props.autoFocus}
      id={props.id}
      type={props.type ?? 'text'}
      name={props.id}
      title={props.title}
      min={props.min}
      max={props.max}
      step={props.step}
      minLength={props.minLength}
      maxLength={props.maxLength}
      autoComplete={props.autoComplete}
      disabled={props.disabled}
      readOnly={props.readOnly}
      value={props.value}
      required={props.required}
      onInvalid={props.onInvalid}
      onChange={props.onChange}
      placeholder={props.placeholder}
      pattern={props.pattern}
      className={`
        h-9
        bg-transparent
        w-full rounded-md sm:text-sm
        ${props.variant === 'minimal' ? '' : ''}
        disabled:opacity-50
        text-gray-600 placeholder:text-gray-400
        border-gray-200 focus:border-gray-400
        focus:shadow-sm
        focus:outline-none
        focus:ring-0
        [&:not(:placeholder-shown):invalid]:text-red-600
        [&:not(:placeholder-shown):invalid]:border-red-400
        [&:focus:not(:placeholder-shown):invalid]:invalid:border-red-400 
        [&:focus:not(:placeholder-shown):invalid]:invalid:ring-red-400
        ${props.variant === 'minimal'
          ? 'border-0 !shadow-none !rounded-none !ring-0 !outline-none'
          : 'border'
        }
        ${props.size === 'small' ? 'py-1 px-2' : ''}
        ${props.size === 'large' ? 'py-3 px-4 lg:text-lg' : ''}
        transition-[border-color] 
        ${props.className ?? ''}
      `}
    />
  </div>;
}

export default memo(Input);
