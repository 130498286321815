interface TwoPanelContainerProps {
  leftPanel: React.ReactNode;
  rightPanel: React.ReactNode;
}

function TwoPanelContainer(props: TwoPanelContainerProps) {
  return <div className='flex-1 flex flex-row h-full mx-3 pb-3 space-x-3'
    style={{
      maxHeight: 'calc(100vh - 2.5rem)',
    }}
  >
    <div className='
      flex flex-col
      min-w-[380px]
      rounded-lg
      overflow-hidden
    '>
      {props.leftPanel}
    </div>
    <div className='
      flex flex-col
      flex-1
      bg-neutral-100
      border border-gray-700
      rounded-xl
      overflow-y-auto
    '>
      {props.rightPanel}
    </div>
  </div>
}

export default TwoPanelContainer;
