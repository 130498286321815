import { useEffect, useState } from 'react';
import { useAgent, useScript } from '../../../hooks';
import { DropDownSelector } from '../../ui';

function ScriptContainer() {
  const [scriptToRender, setScriptToRender] = useState<Script>();

  const { primaryQueueArn } = useAgent();
  const { scripts, getScriptForQueue } = useScript();

  useEffect(() => {
    const defaultScript = getScriptForQueue(primaryQueueArn);

    if (defaultScript) {
      setScriptToRender(defaultScript);
    }
  }, [primaryQueueArn, scripts]);

  const scriptDropDownItems = (scripts ?? []).map((script) => script.Name);

  return <div className='flex flex-col xl:flex-row gap-3'>
    <div className='w-fit min-w-[12rem]'>
      {scriptDropDownItems && scriptToRender && <>
        <label className="block text-xs font-medium text-gray-500 mb-2 ml-2">
          Choose a script
        </label>
        <DropDownSelector
          options={scriptDropDownItems}
          currentOption={scriptToRender.Name}
          onChange={(name) => {
            if (!scripts) return;

            const script = scripts.find((script) => script.Name === name);
            setScriptToRender(script);
          }}
        />
      </>}
    </div>
    <div className='flex-1'>
      {scriptToRender && scriptToRender.Location && <iframe
        src={scriptToRender.Location + '#toolbar=0&view=fitH'}
        className='w-full max-w-7xl min-h-[50rem] rounded-md'
      />}
    </div>
  </div>;
}

export default ScriptContainer;
