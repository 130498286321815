import {
  ChangeEventHandler,
  memo,
  TextareaHTMLAttributes
} from 'react';

type TextAreaProps = {
  variant?: 'minimal';
  label?: string;
  attributes?: TextareaHTMLAttributes<HTMLTextAreaElement>;
  onChange: ChangeEventHandler<HTMLTextAreaElement>; // Making this required.
} & TextareaHTMLAttributes<HTMLTextAreaElement>;

function TextArea(props: TextAreaProps) {
  return <div className='w-full'>
    {props.label && <label
      htmlFor={props.id}
      className="block text-xs font-medium text-gray-500 mb-2 pl-1"
    >
      {props.label}
    </label>}
    <textarea
      autoFocus={props.autoFocus}
      id={props.id}
      name={props.id}
      title={props.title}
      minLength={props.minLength}
      maxLength={props.maxLength}
      autoComplete={props.autoComplete}
      disabled={props.disabled}
      value={props.value}
      required={props.required ?? false}
      onInvalid={props.onInvalid}
      onChange={props.onChange}
      rows={props.rows}
      cols={props.cols}
      placeholder={props.placeholder}
      className={`
        bg-transparent
        w-full rounded-md sm:text-sm
        ${props.variant === 'minimal' ? '' : 'focus:bg-white'}
        disabled:opacity-50
        text-gray-800 placeholder:text-gray-400
        shadow-sm
        border-gray-200 focus:border-gray-400
        focus:outline-none
        [&:not(:placeholder-shown):invalid]:text-red-600
        [&:not(:placeholder-shown):invalid]:border-red-400
        [&:focus:not(:placeholder-shown):invalid]:invalid:border-red-400 
        [&:focus:not(:placeholder-shown):invalid]:invalid:ring-red-400
        ${props.variant === 'minimal'
          ? 'border-0 !shadow-none !rounded-none !ring-0 !outline-none'
          : 'border'
        }
        transition-shadow
        ${props.className}
      `}
      {...props.attributes}
    />
  </div>;
}

export default memo(TextArea);
