import format from 'date-fns-tz/format';
import utcToZonedTime from 'date-fns-tz/utcToZonedTime';

interface CreateCallbackTaskParameters {
  PhoneNumber: string;
  Description: string;
  FirstName: string;
  LastName: string;
  ScheduledTime: string;
  TargetQueueArn: string;
}

async function createCallbackTask(parameters: CreateCallbackTaskParameters) {
  const body = {
    PhoneNumber: parameters.PhoneNumber,
    Description: parameters.Description,
    FirstName: parameters.FirstName,
    LastName: parameters.LastName,
    ScheduledTime: parameters.ScheduledTime,
    TargetQueueArn: parameters.TargetQueueArn
  };

  const result = await fetch(window.createCallbackTaskApi, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  })

  if (!result.ok) {
    throw new Error('Failed to create callback task.');
  }

  const json = await result.json();

  if (!json.ContactId) {
    throw new Error('Failed to create callback task.');
  }

  return {
    ContactId: json.ContactId as string,
  };
}

function toDatetimeLocalFormat(isoDateString: string, timeZone: string) {
  const zoned = utcToZonedTime(isoDateString, timeZone);

  return format(zoned, 'yyyy-MM-dd\'T\'HH:mm:ss');
}

function getAgentQueueArn() {
  const agent = new connect.Agent();
  const agentConfig = agent.getConfiguration();

  return agentConfig.routingProfile.queues
    .find((queue) => queue.queueARN.includes('queue/agent/'))?.queueARN;
}

export {
  createCallbackTask,
  toDatetimeLocalFormat,
  getAgentQueueArn,
};
