import { useEffect, useState } from 'react';


const queueToScriptIdMap: Record<string, string> = {
  'arn:aws:connect:ca-central-1:598851830346:instance/30ed52d8-b087-452e-874c-ba708dbc8ecd/queue/71964de3-cd0a-4141-b997-52aba5346c5b': '11420475-8b4a-4a30-8bbd-871692b8ee0c',
  'arn:aws:connect:ca-central-1:598851830346:instance/30ed52d8-b087-452e-874c-ba708dbc8ecd/queue/2e427274-bd9f-41e0-b0fe-348bd9b0858f': '16bd0feb-e487-4c7b-963d-cf618b7f7deb'
}
// const defaultScriptId = '11420475-8b4a-4a30-8bbd-871692b8ee0c';

function useScript() {
  const [scripts, setScripts] = useState<Script[]>();

  useEffect(() => {
    const url = new URL(window.campaignAgentApi + '/ListScripts');

    url.searchParams.append('namespace', 'GLOBAL');

    fetch(url)
      .then((response) => response.json())
      .then((json) => {
        setScripts(json.data.ScriptList);
      });
  }, []);

  function getScriptForQueue(agentQueueArn?: string) {
    if (!scripts || scripts.length === 0) {
      return null;
    }

    if (!agentQueueArn) {
      return scripts[0];
    }

    const queueDefaultScriptId = queueToScriptIdMap[agentQueueArn];
    let queueDefaultScript = scripts[0];

    scripts.forEach((script) => {
      if (script.Id === queueDefaultScriptId) {
        queueDefaultScript = script;
      }
    });

    return queueDefaultScript;
  }

  const object = {
    scripts: scripts,
    getScriptForQueue: getScriptForQueue
  };

  return object;
}

export default useScript;
