interface LayoutProps {
  children: React.ReactNode;
}

function Layout(props: LayoutProps) {
  return <div className='flex flex-col max-w-full h-screen bg-neutral-800'>
    {props.children}
  </div>
}

export default Layout;
