import { memo } from 'react';

interface LoaderProps {
  // type?: 'dark' | 'light'
  className?: string
}

function Loader(props: LoaderProps) {
  // const color = props.type === 'dark' || props.type === undefined
  //   ? 'text-slate-900' : 'text-slate-100';

  return <div className={props.className}>
    <svg
      className={`animate-spin h-5 w-5 text-slate-100`}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        className="opacity-25"
        cx="12" cy="12" r="10"
        stroke="currentColor"
        strokeWidth="2"
      ></circle>
      <path
        className="opacity-75"
        fill="currentColor"
        d="M 2.994 12 C 3.059 6.37 7.604 2.989 11.981 2.994 L 11.963 0.997
         C 6.188 1.013 1.018 5.592 1.018 12 L 2.994 12 Z M 4.755 17.332 C 3.607
         15.862 2.991 13.765 2.965 11.968 L 1.006 11.98 C 0.872 15.003 2.51
          17.735 3.129 18.504 L 4.755 17.332 Z">
      </path>
    </svg>
  </div>;
}

export default memo(Loader);
