import { useEffect, useState } from 'react';
import type {
  DispositionFolder,
  DispositionItem,
  DispositionTree
} from '../types/dispositions.types';

const queueToDispositionNamespaceMap: Record<string, string> = {
  'arn:aws:connect:ca-central-1:598851830346:instance/30ed52d8-b087-452e-874c-ba708dbc8ecd/queue/71964de3-cd0a-4141-b997-52aba5346c5b': 'INBOUND_IGNITE_SALES',
  'arn:aws:connect:ca-central-1:598851830346:instance/30ed52d8-b087-452e-874c-ba708dbc8ecd/queue/2e427274-bd9f-41e0-b0fe-348bd9b0858f': 'INBOUND_CUSTOMER_SOLUTIONS',
  'arn:aws:connect:ca-central-1:598851830346:instance/30ed52d8-b087-452e-874c-ba708dbc8ecd/queue/486013b5-76da-40c7-a6c2-26337150b608': 'PM_REBOOKING'
}

function getNamespaceForQueue(queueArn?: string) {
  if (!queueArn) return 'GLOBAL';

  const namespace = queueToDispositionNamespaceMap[queueArn];

  if (!namespace) {
    return 'GLOBAL';
  }

  return namespace;
}

function getAllDispositionItems(root: DispositionFolder) {
  const items: DispositionItem[] = [];

  function recurse(folder: DispositionFolder) {
    folder.children.forEach((child) => {
      if (child.type === 'FOLDER') {
        recurse(child);
      } else {
        items.push(child);
      }
    });
  }

  recurse(root);

  return items;
}

function useDisposition(agentQueueArn?: string) {
  const [dispositions, setDispositions] = useState<DispositionCode[]>();
  const [dispositionTree, setDispositionTree] = useState<DispositionTree>();

  useEffect(() => {
    const url = new URL(window.campaignAgentApi + '/GetDispositionTree');

    url.searchParams.append('namespace', getNamespaceForQueue(agentQueueArn));

    fetch(url)
      .then((response) => response.json())
      .then((json) => {
        const tree: DispositionTree = json.data.DispositionTree;
        const codes: DispositionCode[] = getAllDispositionItems(tree.Root)
          .map((item) => ({
            Namespace: tree.Namespace,
            Id: item.id,
            Name: item.label,
          }));

        setDispositions(codes);
        setDispositionTree(tree);
      });
  }, [agentQueueArn]);

  async function setDispositionInbound(
    contactId: string,
    dispositionItem: DispositionItem
  ) {
    // Only useful when testing locally.
    if (!window.inboundAgentApi) {
      return true;
    }

    if (!dispositionTree) throw new Error('Disposition tree not loaded.');

    const url = new URL(window.inboundAgentApi);

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        'ContactId': contactId,
        'DispositionCodeNamespace': dispositionTree.Namespace,
        'DispositionCodeId': dispositionItem.id,
        'DispositionCodeName': dispositionItem.label,
      })
    });

    const json = await response.json();

    if (!response.ok) throw new Error(json.data?.message);

    return true;
  };

  async function setDispositionOutbound(
    contactId: string,
    endpointId: string,
    campaignId: string,
    dispositionItem: DispositionItem
  ) {
    // Only useful when testing locally.
    if (!window.campaignAgentApi) {
      return true;
    }

    if (!dispositionTree) throw new Error('Disposition tree not loaded.');

    const url = new URL(window.campaignAgentApi + '/SetDispositionCode');

    url.searchParams.append('campaignId', campaignId);
    url.searchParams.append('endpointId', endpointId);

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        'ContactId': contactId,
        'DispositionNamespace': dispositionTree.Namespace,
        'DispositionId': dispositionItem.id,
      })
    });

    const json = await response.json();

    if (!response.ok) throw new Error(json.data?.message);

    return true;
  };

  // const withLevels = getLevels(dispositions || []);
  // console.log(JSON.stringify(withLevels, null, 2));

  const object = {
    dispostionTree: dispositionTree,
    dispositions: dispositions,
    // dispositionsWithLevels: withLevels,
    setDispositionInbound: setDispositionInbound,
    setDispositionOutbound: setDispositionOutbound
  };

  return object;
}

export default useDisposition;
