import { useContext, useEffect, useRef } from 'react';
import { DispositionCloakContext } from '../../contexts';

interface ContactCenterPaneProps {
  instanceAlias: string;
}

const VOYSUS_GWS_SAML_URL = 'https://accounts.google.com/o/saml2/' +
  'initsso?idpid=C01ajc5ap&spid=1090988471882&forceauthn=false';
const isLocalhost = window.location.hostname === 'localhost';

function ContactCenterPane(props: ContactCenterPaneProps) {
  const dispositionCloakContext = useContext(DispositionCloakContext);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!ref.current) return;

    // const loginUrl = props.instanceAlias === 'voysus-prod'
    //   ? 'https://account.activedirectory.windowsazure.com/' +
    //   'applications/signin/' +
    //   '428559b7-8c70-4d3d-9af4-cfc3bb777359?' +
    //   'tenantId=b37dfa4b-246c-4d52-95d8-8ea3e4f448ae'
    //   : undefined;

    connect.core.initCCP(ref.current, {
      ccpUrl: 'https://' + props.instanceAlias + '.my.connect.aws/ccp-v2',
      loginUrl:
        props.instanceAlias === 'voysus-prod' && !isLocalhost
          ? VOYSUS_GWS_SAML_URL
          : undefined,
      loginPopup: true,               // optional, defaults to `true`
      loginPopupAutoClose: true,      // optional, defaults to `false`
      loginOptions: {                 // optional, if provided opens login in new window
        autoClose: true,              // optional, defaults to `false`
        height: 600,                  // optional, defaults to 578
        width: 400,                   // optional, defaults to 433
        top: 0,                       // optional, defaults to 0
        left: 0                       // optional, defaults to 0
      },
      softphone: {                    // optional, defaults below apply if not provided
        allowFramedSoftphone: true,   // optional, defaults to false
        disableRingtone: false,       // optional, defaults to false
        // ringtoneUrl: "./ringtone.mp3" // optional, defaults to CCP’s default ringtone if a falsy value is set
      },
      pageOptions: { //optional
        enableAudioDeviceSettings: true, //optional, defaults to 'false'
        enablePhoneTypeSettings: true //optional, defaults to 'true' 
      },
      // ccpAckTimeout: 5000, //optional, defaults to 3000 (ms)
      // ccpSynTimeout: 3000, //optional, defaults to 1000 (ms)
      // ccpLoadTimeout: 10000 //optional, defaults to 5000 (ms)
    });

    connect.contact((contact) => {
      contact.onACW(() => {
        if (contact.getType() !== connect.ContactType.VOICE) {
          dispositionCloakContext.setCanCloseContact(true);
          return;
        }

        // It is crucial to getAttributes() here, because the contact object
        // can be stale by the time the contact has entered ACW.
        //
        // For example, the agent may have set a Disposition during the call.
        const attributes = contact.getAttributes();

        const isCampaignContact = attributes &&
          attributes['attributes_etcContactListId'] != null;

        const noDispositionSet = !(
          attributes.hasOwnProperty('DispositionCodeId') &&
          attributes.DispositionCodeId !== null
        );

        const contactType = contact.getType();

        // We don't want to show the cloak on a Task or Chat contact.
        if (contactType === connect.ContactType.VOICE) {
          const canCloseContact = !noDispositionSet;

          dispositionCloakContext.setCanCloseContact(canCloseContact);
        } else {
          dispositionCloakContext.setCanCloseContact(true);
        }
      });

      contact.onDestroy(() => {
        dispositionCloakContext.setCanCloseContact(true);
      });
    });
  }, [props.instanceAlias]);

  return <div className='flex flex-col flex-1 overflow-hidden'>
    <div className='group relative h-full'>
      <div
        ref={ref}
        id='connect-ccp'
        className={`
        h-full bg-[#f2f2f2] rounded-xl
        ${dispositionCloakContext.canCloseContact
            ? ''
            : 'blur-sm pointer-events-none'
          }
      `}
      ></div>
      {!dispositionCloakContext.canCloseContact && <div>
        <div className='absolute inset-0 bg-black/20 backdrop-blur-sm'></div>
        <div className='absolute inset-0 flex items-center justify-center'>
          <div className='bg-white rounded-xl p-4'>
            <div className='text-center'>
              <div className='text-2xl font-bold mb-2'>Disposition Pending</div>
              <div className='text-sm text-gray-500'>
                Please set a Disposition Code to continue.
              </div>
            </div>
          </div>
        </div>
      </div>}
    </div>
  </div>;
}

export default ContactCenterPane;
