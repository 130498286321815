import { CheckIcon, TrashIcon } from '@heroicons/react/20/solid';
import { memo } from 'react';
import { Button, Form } from '../../ui';

interface CommentProps {
  comment: string;
  setComment: (comment: string) => void;
  onFormSubmit: () => Promise<void>;
}

function Comment(props: CommentProps) {
  return <div className='space-y-3'>
    <h4 className='text-sm font-medium ml-2 select-none'>Comments</h4>
    <Form onSubmit={props.onFormSubmit}>
      {({ isFormSubmitting }) => <div className='space-y-3'>
        <textarea
          className='
            w-full p-3
            border border-gray-300
            text-neutral-800
            rounded-md
            outline-none
            text-sm
          '
          rows={6}
          placeholder='Write a comment...'
          value={props.comment}
          onChange={event => props.setComment(event.target.value)}
        />
        <hr />
        <div className='flex mt-2 justify-between'>
          <div className='flex gap-2'>
            <Button.Secondary
              leadingIcon={TrashIcon}
              label='Clear'
              type='button'
              onClick={() => props.setComment('')}
              disabled={props.comment.length === 0 || isFormSubmitting}
              requireConfirmation={true}
            />
            <Button.Primary
              leadingIcon={CheckIcon}
              label='Save'
              loading={isFormSubmitting}
              disabled={isFormSubmitting}
              type='submit'
            />
          </div>
          {<div className='flex items-center'>
            <span className='text-xs text-neutral-500'>
              Last edited by you on {new Date().toLocaleString('en-US')}
            </span>
          </div>}
        </div>
      </div>}
    </Form>
  </div>
}

export default memo(Comment);
