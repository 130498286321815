import { useCallback, useState } from 'react';
import Comment from './Comment';

function CommentContainer() {
  const [comment, setComment] = useState('An example comment.');

  const handleSetComment = useCallback((comment: string) => {
    setComment(comment);
  }, []);
  const handleOnFormSubmit = useCallback(async () => {
    console.log('Saving comment:', comment);
    // sleep 1 second
    await new Promise(resolve => setTimeout(resolve, 1000));
  }, [comment]);


  return <Comment
    comment={comment}
    setComment={handleSetComment}
    onFormSubmit={handleOnFormSubmit}
  />;
}

export default CommentContainer;
