async function addToDNCList(
  phoneNumber: string,
  metadata: DoNotCallItem['Metadata']
) {
  try {
    const url = new URL(window.campaignAgentApi + '/PutDNCEntry');
    url.searchParams.append('namespace', 'GLOBAL');

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        'PhoneNumber': phoneNumber,
        'Metadata': metadata,
      }),
    });

    const json = await response.json();

    if (!response.ok) throw new Error(json.data?.message);
  } catch (error) {
    console.error(error);
  }
}

function useDNC() {
  async function setDNCInbound(phoneNumber: string, contactId: string) {
    return addToDNCList(phoneNumber, {
      ContactId: contactId,
    });
  };

  async function setDNCOutbound(
    phoneNumber: string,
    contactId: string,
    campaign: {
      campaignId: string,
      campaignName: string,
    },
    contactList: {
      contactListId: string,
      contactListName: string,
    }
  ) {
    return addToDNCList(phoneNumber, {
      ContactId: contactId,
      SourceCampaignId: campaign.campaignId,
      SourceCampaignName: campaign.campaignName,
      SourceContactListId: contactList.contactListId,
      SourceContactListName: contactList.contactListName,
    });
  };

  const object = {
    setDNCInbound: setDNCInbound,
    setDNCOutbound: setDNCOutbound
  };

  return object;
}

export default useDNC;
