import { useEffect, useState } from 'react';

interface DropDownSelectorProps {
  options: string[];
  currentOption: string;
  onChange: (option: string) => void;
}

function DropDownSelector(props: DropDownSelectorProps) {
  const [selectedOption, setSelectedOption] = useState<string>();

  useEffect(() => {
    if (props.currentOption != null) {
      setSelectedOption(props.currentOption);
    }
  }, [props.currentOption]);

  return <select
    className='
      w-full
      py-1 px-2
      rounded-md
      outline-none
      text-sm font-medium text-gray-600
      h-9
      border border-gray-200 focus:border-gray-400
      truncate 
    '
    value={selectedOption}
    onChange={event => {
      setSelectedOption(event.target.value);
      props.onChange(event.target.value);
    }}
  >
    {props.options.map(option => <option key={option} value={option}>
      {option}
    </option>)}
  </select>
}

export default (DropDownSelector);
