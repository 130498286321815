import {
  DocumentTextIcon,
  FolderPlusIcon,
  PhoneIcon
} from '@heroicons/react/20/solid';
import { useContext, useState } from 'react';
import { default as toast } from 'react-hot-toast';
import { DispositionCloakContext } from '../../../contexts';
import { useAgent, useDisposition } from '../../../hooks';
import { DispositionItem } from '../../../types/dispositions.types';
import { Callback } from '../../modals';
import {
  Button,
  Modal,
  MultiLevelDropDownSelector,
  Tab
} from '../../ui';
import { Script } from '../../widgets';

interface InboundControlPaneProps {
  contact: connect.Contact | null;
}

function ControlPanelDefault(props: InboundControlPaneProps) {
  const { primaryQueueArn } = useAgent();
  const {
    dispositions,
    dispostionTree,
    setDispositionInbound
  } = useDisposition(primaryQueueArn);
  const { setCanCloseContact } = useContext(DispositionCloakContext);

  const [callbackModalOpen, setCallbackModalOpen] = useState(false);

  async function handleSetDisposition(dispositionItem: DispositionItem) {
    if (!props.contact) return;

    const promise = setDispositionInbound(
      props.contact.contactId,
      dispositionItem
    );

    if (dispositionItem.label.includes('Callback')) {
      setCallbackModalOpen(true);
    }

    await toast.promise(promise, {
      loading: 'Setting disposition code...',
      success: 'Disposition code set.',
      error: 'Failed to set disposition code.'
    });

    setCanCloseContact(true);
  }

  function handleOnDispositionChange(item: DispositionItem) {
    if (!item || !dispositions) return;

    if (!item) return;

    handleSetDisposition(item);
  }

  return <div className='w-full flex flex-col space-y-4 p-2'>
    <div className='flex flex-col h-1/2 space-y-2'>
      <Tab.Group>
        <div className='flex justify-between items-center'>
          <div>
            <Tab.List>
              <Tab.Item icon={DocumentTextIcon} title='Script' />
            </Tab.List>
          </div>
          <div className='self-end ml-auto flex gap-2 items-center'>
            {props.contact?.getType() === connect.ContactType.VOICE &&
              dispostionTree &&
              <MultiLevelDropDownSelector
                label='Set Disposition Code'
                folder={dispostionTree.Root}
                currentOption={dispostionTree.Root}
                leadingIcon={FolderPlusIcon}
                onChange={handleOnDispositionChange}
              />
            }
            <Button.Secondary
              onClick={() => setCallbackModalOpen(true)}
              label='Schedule Callback'
              leadingIcon={PhoneIcon}
            />
          </div>
        </div>
        <Tab.Panels>
          <Tab.Panel>
            <Script />
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </div>
    <Modal
      open={callbackModalOpen}
      setOpen={setCallbackModalOpen}
      title='Schedule Callback'
    >
      <Callback
        contact={props.contact}
        onComplete={() => setCallbackModalOpen(false)}
      />
    </Modal>
  </div>
}

export default ControlPanelDefault;
