import { useState, FormEvent, memo } from 'react';

interface PassThroughProps {
  isFormSubmitting: boolean
}

interface FormProps {
  onSubmit: (event: FormEvent<HTMLFormElement>) => Promise<unknown>
  children: (props: PassThroughProps) => JSX.Element
}

function Form(props: FormProps) {
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);

  const onSubmitHandler = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setIsFormSubmitting(true);

    props.onSubmit(event)
      .finally(() => setIsFormSubmitting(false));
  };

  return <form onSubmit={onSubmitHandler}>
    {props.children({ isFormSubmitting })}
  </form>;
}

export default memo(Form);
