import { CheckIcon, PencilSquareIcon } from '@heroicons/react/20/solid';
import { useState } from 'react';
import { Button, DropDownSelector } from '../../ui';


const knownFields = {
  "CUSTOMER_CODE": {
    "id": "CUSTOMER_CODE",
    "type": "TEXT",
    "label": "Customer Code",
    "prominent": false,
    "hidden": false,
    "editable": false,
    "value": "123456"
  },
  "PREMISE_CODE": {
    "id": "PREMISE_CODE",
    "type": "TEXT",
    "label": "Premise Code",
    "prominent": false,
    "hidden": false,
    "editable": false,
    "value": "123456"
  },
  "ACCOUNT_CREATION_DATE": {
    "id": "ACCOUNT_CREATION_DATE",
    "type": "TEXT",
    "label": "Account Creation Date",
    "prominent": false,
    "hidden": false,
    "editable": false,
    "value": "2021-01-01"
  },
  "LAST_NAME": {
    "id": "LAST_NAME",
    "type": "TEXT",
    "label": "Last Name",
    "prominent": true,
    "hidden": false,
    "editable": false,
    "value": "Doe"
  },
  "FIRST_NAME": {
    "id": "FIRST_NAME",
    "type": "TEXT",
    "label": "First Name",
    "prominent": true,
    "hidden": false,
    "editable": false,
    "value": "Jane"
  },
  "EMAIL_ADDRESS": {
    "id": "EMAIL_ADDRESS",
    "type": "TEXT",
    "label": "Email Address",
    "prominent": false,
    "hidden": false,
    "editable": false,
    "value": "jane.doe@example.com"
  },
  "PREMISE_ADDRESS": {
    "id": "PREMISE_ADDRESS",
    "type": "TEXT",
    "label": "Address",
    "prominent": true,
    "hidden": false,
    "editable": false,
    "value": "123 Main Street"
  },
  "UNIT_NUM": {
    "id": "UNIT_NUM",
    "type": "TEXT",
    "label": "Unit Num",
    "prominent": true,
    "hidden": false,
    "editable": false,
    "value": "123"
  },
  "CITY": {
    "id": "CITY",
    "type": "TEXT",
    "label": "City",
    "prominent": true,
    "hidden": false,
    "editable": false,
    "value": "Toronto"
  },
  "PROVINCE": {
    "id": "PROVINCE",
    "type": "TEXT",
    "label": "Province",
    "prominent": true,
    "hidden": false,
    "editable": false,
    "value": "Ontario"
  },
  "POSTAL_CODE": {
    "id": "POSTAL_CODE",
    "type": "TEXT",
    "label": "Postal Code",
    "prominent": true,
    "hidden": false,
    "editable": false,
    "value": "M5V 2Z2"
  },
  "MAILING_ADDRESS": {
    "id": "MAILING_ADDRESS",
    "type": "TEXT",
    "label": "Mailing Address",
    "prominent": true,
    "hidden": false,
    "editable": false,
    "value": "123 Main Street"
  },
  "MAILING_UNIT_NUM": {
    "id": "MAILING_UNIT_NUM",
    "type": "TEXT",
    "label": "Mailing Unit Num",
    "prominent": true,
    "hidden": false,
    "editable": false,
    "value": "123"
  },
  "MAILING_CITY": {
    "id": "MAILING_CITY",
    "type": "TEXT",
    "label": "Mailing City",
    "prominent": true,
    "hidden": false,
    "editable": false,
    "value": "Toronto"
  },
  "MAILING_PROVINCE": {
    "id": "MAILING_PROVINCE",
    "type": "TEXT",
    "label": "Mailing Province",
    "prominent": true,
    "hidden": false,
    "editable": false,
    "value": "Ontario"
  },
  "MAILING_POSTAL_CODE": {
    "id": "MAILING_POSTAL_CODE",
    "type": "TEXT",
    "label": "Mailing Postal Code",
    "prominent": true,
    "hidden": false,
    "editable": false,
    "value": "M5V 2Z2"
  },
  "HOME_TELEPHONE": {
    "id": "HOME_TELEPHONE",
    "type": "TEXT",
    "label": "Home Telephone",
    "prominent": true,
    "hidden": false,
    "editable": false,
    "value": "(123) 456-7890"
  },
  "OTHER_TELEPHONE": {
    "id": "OTHER_TELEPHONE",
    "type": "TEXT",
    "label": "Other Telephone",
    "prominent": false,
    "hidden": false,
    "editable": false,
    "value": "(123) 456-7890"
  },
  "WORK_TELEPHONE": {
    "id": "WORK_TELEPHONE",
    "type": "TEXT",
    "label": "Work Telephone",
    "prominent": false,
    "hidden": false,
    "editable": false,
    "value": "(123) 456-7890"
  },
  "OPT06": {
    "id": "OPT06",
    "type": "TEXT",
    "label": "Bill Type",
    "prominent": false,
    "hidden": false,
    "editable": false,
    "value": "Bill Type"
  },
  "OPT08": {
    "id": "OPT08",
    "type": "SINGLE_SELECT",
    "label": "Ebill",
    "prominent": false,
    "hidden": false,
    "editable": true,
    "value": "Yes",
    "options": [
      "Yes",
      "No"
    ]
  },
  "SVC1_DESC": {
    "id": "SVC1_DESC",
    "type": "TEXT",
    "label": "Service 1",
    "prominent": true,
    "hidden": false,
    "editable": false,
    "value": "Service 1"
  },
  "SVC1_RATE": {
    "id": "SVC1_RATE",
    "type": "TEXT",
    "label": "Service 1 Rate",
    "prominent": false,
    "hidden": false,
    "editable": false,
    "value": "Service 1 Rate"
  },
  "SVC2_DESC": {
    "id": "SVC2_DESC",
    "type": "TEXT",
    "label": "Service 2",
    "prominent": true,
    "hidden": false,
    "editable": false,
    "value": "Service 2"
  },
  "SVC2_RATE": {
    "id": "SVC2_RATE",
    "type": "TEXT",
    "label": "Service 2 Rate",
    "prominent": false,
    "hidden": false,
    "editable": false,
    "value": "Service 2 Rate"
  },
  "SVC3_DESC": {
    "id": "SVC3_DESC",
    "type": "TEXT",
    "label": "Service 3",
    "prominent": true,
    "hidden": false,
    "editable": false,
    "value": "Service 3"
  },
  "SVC3_RATE": {
    "id": "SVC3_RATE",
    "type": "TEXT",
    "label": "Service 3 Rate",
    "prominent": false,
    "hidden": false,
    "editable": false,
    "value": "Service 3 Rate"
  },
  "SVC4_DESC": {
    "id": "SVC4_DESC",
    "type": "TEXT",
    "label": "Service 4",
    "prominent": true,
    "hidden": false,
    "editable": false,
    "value": "Service 4"
  },
  "SVC4_RATE": {
    "id": "SVC4_RATE",
    "type": "TEXT",
    "label": "Service 4 Rate",
    "prominent": false,
    "hidden": false,
    "editable": false,
    "value": "Service 4 Rate"
  },
  "SVC5_DESC": {
    "id": "SVC5_DESC",
    "type": "TEXT",
    "label": "Service 5",
    "prominent": true,
    "hidden": false,
    "editable": false,
    "value": "Service 5"
  },
  "SVC5_RATE": {
    "id": "SVC5_RATE",
    "type": "TEXT",
    "label": "Service 5 Rate",
    "prominent": false,
    "hidden": false,
    "editable": false,
    "value": "Service 5 Rate"
  }
}
interface ProfileDetailedProps {
  readonly contact: connect.Contact;
  readonly onUpdate: (updatedProfile: ProfileFieldItem[]) => void;
}

function ProfileDetailed(props: ProfileDetailedProps) {
  const [isTouched, setIsTouched] = useState(false);

  const attributes = props.contact.getAttributes();

  const profileFieldItems: ProfileFieldItem[] = [];

  Object.keys(attributes).forEach((key) => {
    if (key.startsWith('attributes_') && !key.startsWith('attributes_etc')) {
      const attribute = attributes[key];

      attribute.name = attribute.name.replace('attributes_', '');

      if (attribute.name in knownFields) {
        const knownField = knownFields[attribute.name as keyof typeof knownFields];

        const profileFieldItem = {
          id: knownField.id,
          label: knownField.label,
          value: attribute.value,
          type: knownField.type as 'TEXT' | 'SINGLE_SELECT' | 'MULTI_SELECT',
          prominent: knownField.prominent,
          hidden: knownField.hidden,
          editable: knownField.editable,
        };

        profileFieldItems.push(profileFieldItem);
      }
    }
  });

  function handleOnSave() {
    props.onUpdate(profileFieldItems);
  }

  return <div className='space-y-3'>
    <h4 className='text-sm font-medium ml-2 select-none'>Profile</h4>
    <div className='
      grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-4 2xl:grid-cols-5 gap-2
    '>
      {profileFieldItems.map((item, index) => {
        return <div key={index} className='flex px-3 py-2 border rounded'>
          <div className='flex-1 flex flex-col gap-1'>
            <div className='flex space-x-1 items-center text-gray-400 justify-between'>
              <span className='text-xs font-medium select-none'>
                {item.label}
              </span>
              {item.editable && <PencilSquareIcon className='text-sm h-3 w-3' />}
            </div>
            {item.type === 'TEXT' &&
              <p className='text-sm font-medium text-gray-600 bg-transparent'>
                {item.value}
              </p>
            }
            {item.type === 'SINGLE_SELECT' && item.options && <DropDownSelector
              options={item.options}
              currentOption={item.value}
              onChange={(option) => {
                const itemIndex = profileFieldItems
                  .findIndex((profileItem) => profileItem.id === item.id);

                const updatedProfileFieldItems = [...profileFieldItems];

                updatedProfileFieldItems[itemIndex].value = option;

                // setProfileFieldItems(updatedProfileFieldItems);
                setIsTouched(true);
              }}
            />}
          </div>
          {item.editable && <div className='flex flex-row items-center'>
          </div>}
        </div>;
      })}
    </div>
    <hr />
    <div>
      <Button.Primary
        leadingIcon={CheckIcon}
        label='Save'
        disabled={!isTouched}
        onClick={handleOnSave}
      />
    </div>
  </div>

}

export default ProfileDetailed;
