import { Transition } from '@headlessui/react';
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/20/solid';
import { Fragment, memo, useEffect, useRef, useState } from 'react';
import Button from './Button';

interface ItemNode {
  label: string;
}

interface Item extends ItemNode {
  type: 'ITEM';
  id: string;
}

interface Folder extends ItemNode {
  type: 'FOLDER';
  id: string;
  children: (Item | Folder)[];
}

// export interface Option {
//   id: string;
//   label: string;
//   value?: string;
//   children?: Option[];
// }

interface DropdownItemProps {
  disabled?: boolean;
  leadingIcon?: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  trailingIcon?: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  onClick?: () => void;
  className?: string;
  label?: string;
}

function DropdownItem(props: DropdownItemProps) {
  const LeadingIcon = props.leadingIcon;
  const TrailingIcon = props.trailingIcon;

  return <button
    disabled={props.disabled}
    type='button'
    onClick={props.onClick}
    className={`
      relative
      inline-flex items-center
      px-4 py-2
      min-w-[8rem]
      min-h-[2.5rem]
      text-sm
      select-none
      w-full 
      rounded-none 
      ${props.leadingIcon ? '!justify-start' : ''}
      ${props.trailingIcon ? '!justify-between' : ''}
      bg-transparent enabled:hover:bg-neutral-100 enabled:active:bg-neutral-200
      text-gray-700
      disabled:opacity-50
      focus:outline-none
      ${props.className}
    `}
  >
    {LeadingIcon && <LeadingIcon className='h-4 w-4 mr-2' />}
    {props.label && <span className='text-left leading-6'>{props.label}</span>}
    {TrailingIcon && <TrailingIcon className='h-4 w-4 ml-2' />}
  </button>;
}

interface MultiLevelDropDownSelectorProps {
  label?: string;
  leadingIcon?: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  trailingIcon?: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  folder: Folder;
  currentOption: any;
  onChange: (option: Item) => void;
}

function MultiLevelDropDownSelector(props: MultiLevelDropDownSelectorProps) {
  const ref = useRef<HTMLDivElement>(null);
  const [selectedOption, setSelectedOption] = useState<Folder>();
  const [open, setOpen] = useState(false);

  const optionsToRender = selectedOption ?? props.folder;

  // if dropdown is open and the user clicks outside of the dropdown, close it
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [open]);

  function handleToggleDropdown() {
    if (open === true) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }

  return <div ref={ref} className='relative'>
    <Button.Primary
      label={props.label}
      leadingIcon={props.leadingIcon}
      trailingIcon={props.trailingIcon}
      onClick={handleToggleDropdown}
    />
    <Transition
      as={Fragment}
      enter='transition ease-out duration-100'
      enterFrom='opacity-0 scale-95'
      enterTo='opacity-100 translate-y-[0] scale-100'
      leave='transition ease-in duration-150'
      leaveFrom='opacity-100'
      leaveTo='opacity-0 translate-y-[-3%] scale-95'
      show={open}
    >
      <div className='
        absolute max-h-96
        overflow-auto rounded-md bg-white shadow-lg border border-neutral-200
        ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm
        z-50
        w-full min-w-[18rem]
        right-0
        divide-y divide-neutral-200
        mt-2
    '>
        {selectedOption?.id != null && <DropdownItem
          onClick={() => {
            if (selectedOption.id != null) {
              setSelectedOption(undefined);
            }
          }}
          leadingIcon={ArrowLeftIcon}
          label='Back'
          className='font-semibold'
        />}
        {optionsToRender.children.map(child => <DropdownItem
          key={child.id}
          trailingIcon={child.type === 'FOLDER' ? ArrowRightIcon : undefined}
          label={child.label}
          onClick={() => {
            if (child.type === 'FOLDER' && child.children) {
              setSelectedOption(child);
            } else if (child.type === 'ITEM') {
              props.onChange(child);
              setOpen(false);
            }
          }}
        />)}
      </div>
    </Transition>
  </div>;
}

export default memo(MultiLevelDropDownSelector);
