import { ExclamationCircleIcon } from '@heroicons/react/20/solid';
import {
  ButtonHTMLAttributes,
  ComponentType,
  MouseEvent,
  SVGProps,
  useCallback,
  useEffect,
  useState
} from 'react';
import Loader from './Loader';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  label?: string;
  loading?: boolean;
  leadingIcon?: ComponentType<SVGProps<SVGSVGElement>>;
  trailingIcon?: ComponentType<SVGProps<SVGSVGElement>>;
  requireConfirmation?: boolean;
  hideShadow?: boolean;
}

interface ButtonState {
  isConfirming: boolean;
  isLoading: boolean;
}

interface BaseButtonProps {
  className: (state: ButtonState) => string;
  passThroughProps: ButtonProps;
}

function BaseButton(props: BaseButtonProps) {
  const [label, setLabel] = useState(props.passThroughProps.label);
  const [isConfirming, setIsConfirming] = useState(false);
  const [isLoading, setIsLoading] = useState(
    props.passThroughProps.loading ?? false
  );

  useEffect(() => {
    setIsLoading(props.passThroughProps.loading ?? false);
  }, [props.passThroughProps.loading]);

  useEffect(() => {
    setLabel(props.passThroughProps.label);
  }, [props.passThroughProps.label]);

  const handleClick = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    if (typeof props.passThroughProps.onClick !== 'function') {
      return;
    }

    if (!props.passThroughProps.requireConfirmation) {
      props.passThroughProps.onClick(event);
    }

    if (props.passThroughProps.requireConfirmation && !isConfirming) {
      setLabel('Confirm');
      setIsConfirming(true);
    } else if (props.passThroughProps.requireConfirmation && isConfirming) {
      setIsConfirming(false);
      setLabel(props.passThroughProps.label);

      props.passThroughProps.onClick(event);
    }
  }, [isConfirming, label, props.passThroughProps.onClick]);

  const LeadingIcon = props.passThroughProps.requireConfirmation
    ? isConfirming
      ? ExclamationCircleIcon
      : props.passThroughProps.leadingIcon
    : props.passThroughProps.leadingIcon;

  const TrailingIcon = props.passThroughProps.trailingIcon;

  return <button
    disabled={props.passThroughProps.disabled}
    type={props.passThroughProps.type}
    onClick={handleClick}
    className={`
      relative
      inline-flex items-center justify-center
      px-3 py-1 h-9 min-w-[8rem]
      text-sm font-medium
      rounded-md
      ${!props.passThroughProps.hideShadow &&
      'enabled:hover:shadow enabled:active:shadow-sm'}
      focus:outline-none
      select-none
      transition ease-in-out duration-75
      ${props.className({ isConfirming, isLoading })}
      ${props.passThroughProps.className ?? ''}
    `}
  >
    {LeadingIcon && <LeadingIcon className={`h-4 w-4 mr-2 ${isLoading && 'opacity-0'}`} />}
    {label && <span className={`${isLoading && 'opacity-0'}`}>{label}</span>}
    {isLoading && <Loader className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2' />}
    {TrailingIcon && <TrailingIcon className={`h-4 w-4 ml-2 ${isLoading && 'opacity-0'}`} />}
  </button>;
}

function PrimaryButton(props: ButtonProps) {
  return <BaseButton
    className={({ isConfirming, isLoading }) => `
      bg-indigo-500 enabled:hover:bg-indigo-600 enabled:active:bg-indigo-700
      border border-indigo-600
      text-neutral-100
      disabled:opacity-50
      focus:outline-none focus:border-gray-700 focus:shadow-outline-gray
    `}
    passThroughProps={props}
  />
}

function SecondaryButton(props: ButtonProps) {
  return <BaseButton
    className={({ isConfirming, isLoading }) => `
      ${isConfirming
        ? 'bg-red-200 enabled:hover:bg-red-200 enabled:active:bg-red-300'
        : 'bg-neutral-100 enabled:hover:bg-neutral-100 enabled:active:bg-neutral-200'
      }
      ${isConfirming
        ? 'border border-red-300 active:border-red-400'
        : 'border border-gray-300'
      }
      ${isConfirming ? 'text-red-800 active:text-red-900' : 'text-neutral-700'}
      disabled:opacity-50
      focus:outline-none 
      ${isConfirming ? 'focus:border-red-300' : 'focus:border-gray-400'}
    `}
    passThroughProps={props}
  />
}

function TertiaryButton(props: ButtonProps) {
  return <BaseButton
    className={({ isConfirming, isLoading }) => `
      bg-transparent enabled:hover:bg-neutral-100 enabled:active:bg-neutral-200
      border border-gray-300 active:border-gray-400
      text-neutral-700
      disabled:opacity-50
      focus:outline-none
    `}
    passThroughProps={props}
  />
}

export default {
  Primary: PrimaryButton,
  Secondary: SecondaryButton,
  Tertiary: TertiaryButton
};
