import { createContext, createElement, useState } from 'react';

const DispositionCloakContext = createContext({
  canCloseContact: true,
  setCanCloseContact: (canAgentCloseContact: boolean) => { },
});

interface DispositionCloackProviderProps {
  children: React.ReactNode;
}

function DispositionCloakProvider(props: DispositionCloackProviderProps) {
  const [canCloseContact, setCanCloseContact] = useState(true);

  const dispositionCloack = {
    canCloseContact: canCloseContact,
    setCanCloseContact: setCanCloseContact,
  }

  return createElement(DispositionCloakContext.Provider, {
    value: dispositionCloack
  }, props.children);
}

export default DispositionCloakContext;
export { DispositionCloakProvider };
