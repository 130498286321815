import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Fragment, memo } from 'react';

interface ModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  title?: string;
  description?: string;
  fluid?: boolean;
  children: React.ReactNode;
}

function Modal(props: ModalProps) {
  return <>
    <Transition appear as={Fragment} show={props.open}>
      <Dialog
        as='div'
        className='fixed inset-0 z-50 overflow-y-auto'
        onClose={() => props.setOpen(false)}
      >
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-200'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-100'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <Dialog.Overlay className='fixed inset-0 bg-gray-300/50' />
        </Transition.Child>
        <div className='flex justify-center px-4 mt-24 lg:mt-24'>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-250 delay-150'
            enterFrom='opacity-0 scale-95'
            enterTo='opacity-100 scale-100'
            leave='ease-in duration-100'
            leaveFrom='opacity-100 scale-100'
            leaveTo='opacity-0 scale-95'
          >
            <Dialog.Panel
              className={`
                block bg-white border border-gray-200 w-full
                p-6 text-left align-middle shadow-lg
                rounded-xl transition-all transform
                ${props.fluid ? 'max-w-4xl' : 'max-w-lg'}
              `}>
              <div className='space-y-4'>
                <div className='space-y-4'>
                  <div className='flex'>
                    {props.title &&
                      <Dialog.Title className='text-md font-medium leading-6 
                      text-gray-900'>{props.title}</Dialog.Title>
                    }
                    <div className='flex-1 flex justify-end items-center'>
                      <div onClick={() => props.setOpen(false)} className='
                        cursor-pointer
                        hover:bg-neutral-200 active:bg-neutral-300
                        rounded-md p-1
                        transition-colors 
                      '>
                        <XMarkIcon
                          height={16}
                          width={16}
                          className='
                            text-neutral-500 
                            transition-transform active:scale-90
                          '
                        />
                      </div>
                    </div>
                  </div>
                  {props.description &&
                    <Dialog.Description
                      className='text-sm text-gray-500 max-w-[95%]'
                    >
                      {props.description}
                    </Dialog.Description>
                  }
                </div>
                <div>
                  {props.children}
                </div>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  </>;
}

export default memo(Modal);

