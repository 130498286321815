import { PlusCircleIcon } from '@heroicons/react/20/solid';
import { zonedTimeToUtc } from 'date-fns-tz';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { TIME_ZONES } from '../../../constants';
import { Button, DropDownSelector, Form, Input, TextArea } from '../../ui';

import {
  createCallbackTask,
  getAgentQueueArn,
  toDatetimeLocalFormat
} from './Callback.utils';

const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

interface CallbackProps {
  contact: connect.Contact | null;
  onComplete: () => void;
}

function Callback(props: CallbackProps) {
  const [routeToAgent, setRouteToAgent] = useState(false);
  const [timeZone, setTimeZone] = useState(localTimeZone);

  const [payload, setPayload] = useState({
    'Description': '',
    'PhoneNumber': '',
    'FirstName': '',
    'LastName': '',
    'ScheduledTime': new Date().toISOString(),
    'TargetQueueArn': ''
  });

  function getContactQueueArn() {
    if (!props.contact) {
      return null;
    }

    return props.contact.getQueue().queueARN;
  }

  const onFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    // Make sure the scheduled time is in the future. If not, set an error.
    const scheduledTime = new Date(payload.ScheduledTime).getTime();
    const now = +Date.now();

    if (scheduledTime <= now) {
      toast.error('Scheduled time must be in the future.');
      return;
    }

    let targetQueueArn = null;

    if (routeToAgent) {
      targetQueueArn = getAgentQueueArn();
    } else {
      targetQueueArn = getContactQueueArn();
    }

    if (!targetQueueArn) {
      toast.error('Failed to get the Queue ARN (Agent or Direct).');
      return;
    }

    const createCallbackTaskPromise = createCallbackTask({
      PhoneNumber: payload.PhoneNumber,
      Description: payload.Description,
      FirstName: payload.FirstName,
      LastName: payload.LastName,
      ScheduledTime: payload.ScheduledTime,
      TargetQueueArn: targetQueueArn,
    });

    await toast.promise(createCallbackTaskPromise, {
      loading: 'Creating callback task...',
      success: 'Callback task created successfully!',
      error: 'Failed to create callback task.',
    });

    props.onComplete();
  };

  return <Form onSubmit={onFormSubmit}>
    {({ isFormSubmitting }) => <>
      <div className='flex flex-col items-center justify-center space-y-8'>
        <div className='flex flex-col justify-center w-full space-y-4'>
          <div className='flex flex-col space-y-4'>
            <Input
              label='Phone Number'
              type='tel'
              value={payload.PhoneNumber}
              onChange={(event) => setPayload({
                ...payload,
                'PhoneNumber': event.target.value
              })}
              // Match E.164 format US phone numbers.
              pattern='^\+1[0-9]{10}$'
              title='Phone number must be in E.164 format. Ex: +1234567890.'
              placeholder='+1234567890'
              size='normal'
              className='px-3'
              required
            />
            <TextArea
              id='description'
              label='Description'
              placeholder='Customer wants to be called back.'
              value={payload.Description}
              onChange={(event) => setPayload({
                ...payload,
                'Description': event.target.value
              })}
              maxLength={1024}
              rows={10}
              title='Description'
              className='px-3 py-2'
              required
            />
          </div>
          <div className='flex flex-row space-x-2'>
            <Input
              type='text'
              label='First Name'
              value={payload.FirstName}
              required={true}
              minLength={1}
              maxLength={1024}
              onChange={(event) => setPayload({
                ...payload,
                'FirstName': event.target.value
              })}
              placeholder='John'
              size='normal'
              className='px-3'
            />
            <Input
              type='text'
              label='Last Name'
              value={payload.LastName}
              required={true}
              minLength={1}
              maxLength={1024}
              onChange={(event) => setPayload({
                ...payload,
                'LastName': event.target.value
              })}
              placeholder='Doe'
              size='normal'
              className='px-3'
            />
          </div>
          <div className='flex flex-row space-x-2 items-center'>
            <Input
              label='Scheduled Time'
              type='datetime-local'
              value={toDatetimeLocalFormat(payload.ScheduledTime, timeZone)}
              onChange={(event) => {
                const zoned = zonedTimeToUtc(event.target.value, timeZone);

                setPayload({
                  ...payload,
                  'ScheduledTime': zoned.toISOString()
                });
              }}
              // only allow now to 7 days from now
              max={toDatetimeLocalFormat(
                (new Date(Date.now() + 7 * 24 * 60 * 60 * 1000)).toISOString(),
                timeZone
              )}
              size='normal'
              className='px-3'
              required
            />
            <div className='w-80'>
              <label className="block text-xs font-medium text-gray-500 mb-2">
                Time Zone
              </label>
              <DropDownSelector
                options={TIME_ZONES}
                currentOption={timeZone}
                onChange={setTimeZone}
              />
            </div>
          </div>
        </div>
        <div className='flex items-center justify-between space-x-4 w-full'>
          <div className='flex flex-row space-x-2 items-center'>
            <input
              id='route-to-agent'
              name='route-to-agent'
              type='checkbox'
              checked={routeToAgent}
              onChange={(event) => setRouteToAgent(event.target.checked)}
              disabled={!getContactQueueArn()}
              className='
                focus:ring-indigo-500 h-4 w-4 text-indigo-600
                border-gray-300
                rounded'
            />
            <label
              htmlFor='route-to-agent'
              className='text-sm font-medium text-gray-700'
            >
              Route to myself
            </label>
          </div>
          <Button.Primary
            label='Submit'
            type='submit'
            leadingIcon={PlusCircleIcon}
            loading={isFormSubmitting}
          />
        </div>
      </div>
    </>}
  </Form>
}

export default Callback;
