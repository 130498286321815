import { PencilSquareIcon } from '@heroicons/react/20/solid';

function Item({ item }: { item: ProfileFieldItem }) {
  return <div className='flex px-3 py-2 border rounded'>
    <div className='flex-1 flex flex-col gap-1'>
      <div className='flex space-x-1 items-center text-gray-400 justify-between'>
        <span className='text-xs font-medium select-none'>
          {item.label}
        </span>
        {item.editable && <PencilSquareIcon className='text-sm h-3 w-3' />}
      </div>
      {item.type === 'TEXT' &&
        <p className='text-sm font-medium text-gray-600 bg-transparent'>
          {item.value}
        </p>
      }
    </div>
    {item.editable && <div className='flex flex-row items-center'>
    </div>}
  </div>;
}

const knownFields = {
  "CUSTOMER_CODE": {
    "id": "CUSTOMER_CODE",
    "type": "TEXT",
    "label": "Customer Code",
    "prominent": false,
    "hidden": false,
    "editable": false,
    "value": "123456"
  },
  "PREMISE_CODE": {
    "id": "PREMISE_CODE",
    "type": "TEXT",
    "label": "Premise Code",
    "prominent": false,
    "hidden": false,
    "editable": false,
    "value": "123456"
  },
  "ACCOUNT_CREATION_DATE": {
    "id": "ACCOUNT_CREATION_DATE",
    "type": "TEXT",
    "label": "Account Creation Date",
    "prominent": false,
    "hidden": false,
    "editable": false,
    "value": "2021-01-01"
  },
  "LAST_NAME": {
    "id": "LAST_NAME",
    "type": "TEXT",
    "label": "Last Name",
    "prominent": true,
    "hidden": false,
    "editable": false,
    "value": "Doe"
  },
  "FIRST_NAME": {
    "id": "FIRST_NAME",
    "type": "TEXT",
    "label": "First Name",
    "prominent": true,
    "hidden": false,
    "editable": false,
    "value": "Jane"
  },
  "EMAIL_ADDRESS": {
    "id": "EMAIL_ADDRESS",
    "type": "TEXT",
    "label": "Email Address",
    "prominent": false,
    "hidden": false,
    "editable": false,
    "value": "jane.doe@example.com"
  },
  "PREMISE_ADDRESS": {
    "id": "PREMISE_ADDRESS",
    "type": "TEXT",
    "label": "Address",
    "prominent": true,
    "hidden": false,
    "editable": false,
    "value": "123 Main Street"
  },
  "UNIT_NUM": {
    "id": "UNIT_NUM",
    "type": "TEXT",
    "label": "Unit Num",
    "prominent": true,
    "hidden": false,
    "editable": false,
    "value": "123"
  },
  "CITY": {
    "id": "CITY",
    "type": "TEXT",
    "label": "City",
    "prominent": true,
    "hidden": false,
    "editable": false,
    "value": "Toronto"
  },
  "PROVINCE": {
    "id": "PROVINCE",
    "type": "TEXT",
    "label": "Province",
    "prominent": true,
    "hidden": false,
    "editable": false,
    "value": "Ontario"
  },
  "POSTAL_CODE": {
    "id": "POSTAL_CODE",
    "type": "TEXT",
    "label": "Postal Code",
    "prominent": true,
    "hidden": false,
    "editable": false,
    "value": "M5V 2Z2"
  },
  "MAILING_ADDRESS": {
    "id": "MAILING_ADDRESS",
    "type": "TEXT",
    "label": "Mailing Address",
    "prominent": true,
    "hidden": false,
    "editable": false,
    "value": "123 Main Street"
  },
  "MAILING_UNIT_NUM": {
    "id": "MAILING_UNIT_NUM",
    "type": "TEXT",
    "label": "Mailing Unit Num",
    "prominent": true,
    "hidden": false,
    "editable": false,
    "value": "123"
  },
  "MAILING_CITY": {
    "id": "MAILING_CITY",
    "type": "TEXT",
    "label": "Mailing City",
    "prominent": true,
    "hidden": false,
    "editable": false,
    "value": "Toronto"
  },
  "MAILING_PROVINCE": {
    "id": "MAILING_PROVINCE",
    "type": "TEXT",
    "label": "Mailing Province",
    "prominent": true,
    "hidden": false,
    "editable": false,
    "value": "Ontario"
  },
  "MAILING_POSTAL_CODE": {
    "id": "MAILING_POSTAL_CODE",
    "type": "TEXT",
    "label": "Mailing Postal Code",
    "prominent": true,
    "hidden": false,
    "editable": false,
    "value": "M5V 2Z2"
  },
  "HOME_TELEPHONE": {
    "id": "HOME_TELEPHONE",
    "type": "TEXT",
    "label": "Home Telephone",
    "prominent": true,
    "hidden": false,
    "editable": false,
    "value": "(123) 456-7890"
  },
  "OTHER_TELEPHONE": {
    "id": "OTHER_TELEPHONE",
    "type": "TEXT",
    "label": "Other Telephone",
    "prominent": false,
    "hidden": false,
    "editable": false,
    "value": "(123) 456-7890"
  },
  "WORK_TELEPHONE": {
    "id": "WORK_TELEPHONE",
    "type": "TEXT",
    "label": "Work Telephone",
    "prominent": false,
    "hidden": false,
    "editable": false,
    "value": "(123) 456-7890"
  },
  "OPT06": {
    "id": "OPT06",
    "type": "TEXT",
    "label": "Bill Type",
    "prominent": false,
    "hidden": false,
    "editable": false,
    "value": "Bill Type"
  },
  "OPT08": {
    "id": "OPT08",
    "type": "SINGLE_SELECT",
    "label": "Ebill",
    "prominent": false,
    "hidden": false,
    "editable": true,
    "value": "Yes",
    "options": [
      "Yes",
      "No"
    ]
  },
  "SVC1_DESC": {
    "id": "SVC1_DESC",
    "type": "TEXT",
    "label": "Service 1",
    "prominent": true,
    "hidden": false,
    "editable": false,
    "value": "Service 1"
  },
  "SVC1_RATE": {
    "id": "SVC1_RATE",
    "type": "TEXT",
    "label": "Service 1 Rate",
    "prominent": false,
    "hidden": false,
    "editable": false,
    "value": "Service 1 Rate"
  },
  "SVC2_DESC": {
    "id": "SVC2_DESC",
    "type": "TEXT",
    "label": "Service 2",
    "prominent": true,
    "hidden": false,
    "editable": false,
    "value": "Service 2"
  },
  "SVC2_RATE": {
    "id": "SVC2_RATE",
    "type": "TEXT",
    "label": "Service 2 Rate",
    "prominent": false,
    "hidden": false,
    "editable": false,
    "value": "Service 2 Rate"
  },
  "SVC3_DESC": {
    "id": "SVC3_DESC",
    "type": "TEXT",
    "label": "Service 3",
    "prominent": true,
    "hidden": false,
    "editable": false,
    "value": "Service 3"
  },
  "SVC3_RATE": {
    "id": "SVC3_RATE",
    "type": "TEXT",
    "label": "Service 3 Rate",
    "prominent": false,
    "hidden": false,
    "editable": false,
    "value": "Service 3 Rate"
  },
  "SVC4_DESC": {
    "id": "SVC4_DESC",
    "type": "TEXT",
    "label": "Service 4",
    "prominent": true,
    "hidden": false,
    "editable": false,
    "value": "Service 4"
  },
  "SVC4_RATE": {
    "id": "SVC4_RATE",
    "type": "TEXT",
    "label": "Service 4 Rate",
    "prominent": false,
    "hidden": false,
    "editable": false,
    "value": "Service 4 Rate"
  },
  "SVC5_DESC": {
    "id": "SVC5_DESC",
    "type": "TEXT",
    "label": "Service 5",
    "prominent": true,
    "hidden": false,
    "editable": false,
    "value": "Service 5"
  },
  "SVC5_RATE": {
    "id": "SVC5_RATE",
    "type": "TEXT",
    "label": "Service 5 Rate",
    "prominent": false,
    "hidden": false,
    "editable": false,
    "value": "Service 5 Rate"
  }
}

interface ProfilePreviewProps {
  contact: connect.Contact;
}

function ProfilePreview(props: ProfilePreviewProps) {
  const attributes = props.contact.getAttributes();

  const profileFieldItems: ProfileFieldItem[] = [];

  Object.keys(attributes).forEach((key) => {
    if (key.startsWith('attributes_') && !key.startsWith('attributes_etc')) {
      const attribute = attributes[key];

      attribute.name = attribute.name.replace('attributes_', '');

      if (attribute.name in knownFields) {
        const knownField = knownFields[attribute.name as keyof typeof knownFields];

        const profileFieldItem = {
          id: knownField.id,
          label: knownField.label,
          value: attribute.value,
          type: knownField.type as 'TEXT' | 'SINGLE_SELECT' | 'MULTI_SELECT',
          prominent: knownField.prominent,
          hidden: knownField.hidden,
          editable: knownField.editable,
        };

        profileFieldItems.push(profileFieldItem);
      }
    }
  });

  const indexFirstName = profileFieldItems.findIndex(i => i.id === 'FIRST_NAME');
  const indexLastName = profileFieldItems.findIndex(i => i.id === 'LAST_NAME');
  const indexEmail = profileFieldItems.findIndex(i => i.id === 'EMAIL_ADDRESS');
  const indexPremiseAddress = profileFieldItems.findIndex(i => i.id === 'PREMISE_ADDRESS');
  const indexMailingAddress = profileFieldItems.findIndex(i => i.id === 'MAILING_ADDRESS');
  const indexUnitNum = profileFieldItems.findIndex(i => i.id === 'UNIT_NUM');
  const indexMailingUnitNum = profileFieldItems.findIndex(i => i.id === 'MAILING_UNIT_NUM');
  const indexCity = profileFieldItems.findIndex(i => i.id === 'CITY');
  const indexMailingCity = profileFieldItems.findIndex(i => i.id === 'MAILING_CITY');
  const indexProvince = profileFieldItems.findIndex(i => i.id === 'PROVINCE');
  const indexMailingProvince = profileFieldItems.findIndex(i => i.id === 'MAILING_PROVINCE');
  const indexPostalCode = profileFieldItems.findIndex(i => i.id === 'POSTAL_CODE');
  const indexMailingPostalCode = profileFieldItems.findIndex(i => i.id === 'MAILING_POSTAL_CODE');
  const indexSvc1Desc = profileFieldItems.findIndex(i => i.id === 'SVC1_DESC');
  const indexSvc2Desc = profileFieldItems.findIndex(i => i.id === 'SVC2_DESC');
  const indexSvc3Desc = profileFieldItems.findIndex(i => i.id === 'SVC3_DESC');
  const indexSvc4Desc = profileFieldItems.findIndex(i => i.id === 'SVC4_DESC');
  const indexSvc5Desc = profileFieldItems.findIndex(i => i.id === 'SVC5_DESC');

  return <div className='space-y-3'>
    <h4 className='text-sm font-medium ml-2 select-none'>Profile</h4>
    <div className='flex gap-2'>
      <div className='flex flex-col w-56 gap-2'>
        <Item item={profileFieldItems[indexFirstName]} />
        <Item item={profileFieldItems[indexPremiseAddress]} />
        <Item item={profileFieldItems[indexUnitNum]} />
        <Item item={profileFieldItems[indexCity]} />
        <Item item={profileFieldItems[indexProvince]} />
        <Item item={profileFieldItems[indexPostalCode]} />
      </div>
      <div className='flex flex-col w-56 gap-2'>
        <Item item={profileFieldItems[indexLastName]} />
        <Item item={profileFieldItems[indexMailingAddress]} />
        <Item item={profileFieldItems[indexMailingUnitNum]} />
        <Item item={profileFieldItems[indexMailingCity]} />
        <Item item={profileFieldItems[indexMailingProvince]} />
        <Item item={profileFieldItems[indexMailingPostalCode]} />
      </div>
      <div className='flex flex-col w-72 gap-2'>
        <Item item={profileFieldItems[indexSvc1Desc]} />
        <Item item={profileFieldItems[indexSvc2Desc]} />
        <Item item={profileFieldItems[indexSvc3Desc]} />
        <Item item={profileFieldItems[indexSvc4Desc]} />
        <Item item={profileFieldItems[indexSvc5Desc]} />
      </div>
    </div>
  </div>
}

export default ProfilePreview;
