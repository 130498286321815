import { Tab as HeadlessTab } from '@headlessui/react';

interface TabItemProps {
  icon: React.ComponentType<React.ComponentProps<'svg'>>;
  title: string;
}

function TabItem(props: TabItemProps) {
  const Icon = props.icon;

  return <HeadlessTab
    className={({ selected }) => `
      min-w-[4rem] rounded-lg px-3 py-1 text-sm font-medium leading-5
      outline-none border border-transparent
      flex items-center justify-center
      select-none
      ${selected
        ? 'bg-neutral-600 text-gray-100 border-gray-700'
        : 'bg-neutral-200 hover:bg-black/[0.12]'}
    `}
  >
    <Icon className='h-4 w-4 inline-block mr-2' />
    <span>{props.title}</span>
  </HeadlessTab>
}

interface TabPanelProps {
  children: React.ReactNode;
}

function TabPanel(props: TabPanelProps) {
  return <HeadlessTab.Panel className='rounded-xl bg-white p-3 flex-1 border'>
    {props.children}
  </HeadlessTab.Panel>
}

function TabGroup(props: React.PropsWithChildren<{}>) {
  return <HeadlessTab.Group>{props.children} </HeadlessTab.Group>;
}

function TabPanels(props: React.PropsWithChildren<{}>) {
  return <HeadlessTab.Panels className='flex'>
    {props.children}
  </HeadlessTab.Panels>;
}

function TabList(props: React.PropsWithChildren<{}>) {
  return <HeadlessTab.List className='flex space-x-2 max-w-fit'>
    {props.children}
  </HeadlessTab.List>;
}

export default {
  Item: TabItem,
  Panel: TabPanel,
  Group: TabGroup,
  Panels: TabPanels,
  List: TabList
};
