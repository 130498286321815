import {
  DocumentTextIcon,
  FolderPlusIcon,
  InformationCircleIcon,
  PhoneIcon,
  UserIcon
} from '@heroicons/react/20/solid';
import { useContext, useState } from 'react';
import { toast } from 'react-hot-toast';
import { DispositionCloakContext } from '../../../contexts';
import { useAgent, useDNC, useDisposition } from '../../../hooks';
import { DispositionItem } from '../../../types/dispositions.types';
import { Callback } from '../../modals';
import { Button, Modal, Tab } from '../../ui';
import MultiLevelDropDownSelector from '../../ui/MultiLevelDropDownSelector';
import {
  CommentContainer,
  Information,
  ProfileDetailed,
  ProfilePreview,
  Script
} from '../../widgets';

interface ContactCenterPaneProps {
  contact: connect.Contact | null;
}

function ControlPanelCampaign(props: ContactCenterPaneProps) {
  const { setDNCOutbound } = useDNC();
  const { primaryQueueArn } = useAgent();
  const {
    dispositions,
    setDispositionOutbound,
    dispostionTree
  } = useDisposition(primaryQueueArn);
  const { setCanCloseContact } = useContext(DispositionCloakContext);

  const [callbackModalOpen, setCallbackModalOpen] = useState(false);

  async function handleSetDisposition(dispositionCode: DispositionItem) {
    if (!props.contact) return;

    const attributes = props.contact.getAttributes();

    const endpointId = attributes['endpointId'].value;
    const campaignId = attributes['attributes_etcCampaignId'].value;

    if (!endpointId || !campaignId) {
      console.error('AUI:OutboundControlPane:Missing endpointId or campaignId.');
      return;
    }

    if (dispositionCode.label.includes('Do Not Call')) {
      let phoneNumber = endpointId.split(':')[1];

      if (!phoneNumber) {
        phoneNumber = prompt(
          'Please enter the phone number to add to the DNC list:'
        ) ?? '';
      }

      if (phoneNumber) {
        const dncPromise = setDNCOutbound(phoneNumber, props.contact.contactId, {
          campaignId: campaignId,
          campaignName: attributes['attributes_etcCampaignName'].value
        }, {
          contactListId: attributes['attributes_etcContactListId'].value,
          contactListName: attributes['attributes_etcContactListName'].value
        });

        await toast.promise(dncPromise, {
          loading: 'Adding to DNC list..',
          success: 'Added to DNC list.',
          error: 'Failed to add to DNC list.'
        });
      }
    }

    if (
      dispositionCode.label.includes('Personal') ||
      dispositionCode.label.includes('Group')
    ) {
      setCallbackModalOpen(true);
    }

    const promise = setDispositionOutbound(
      props.contact.contactId,
      endpointId,
      campaignId,
      dispositionCode
    );
    await toast.promise(promise, {
      loading: 'Setting disposition code...',
      success: 'Disposition code set.',
      error: 'Failed to set disposition code.'
    });

    setCanCloseContact(true);
  }

  function handleOnDispositionChange(item: DispositionItem) {
    if (!item || !dispositions) return;

    if (!item) return;

    handleSetDisposition(item);
  }

  return <div className='w-full flex flex-col space-y-4 p-2'>
    <div className='flex flex-col space-y-2'>
      <div className='self-end flex gap-2 items-center'>
        {dispostionTree && <MultiLevelDropDownSelector
          label='Set Disposition Code'
          folder={dispostionTree.Root}
          currentOption={dispostionTree.Root}
          leadingIcon={FolderPlusIcon}
          onChange={handleOnDispositionChange}
        />}
        <Button.Secondary
          onClick={() => setCallbackModalOpen(true)}
          label='Create Callback'
          leadingIcon={PhoneIcon}
        />
        <Modal
          open={callbackModalOpen}
          setOpen={setCallbackModalOpen}
          title='Schedule Callback'
        >
          <Callback
            contact={props.contact}
            onComplete={() => setCallbackModalOpen(false)}
          />
        </Modal>
      </div>
    </div>
    <div className='flex flex-col space-y-2'>
      <div className='rounded-xl bg-white p-3 flex-1 border space-y-6'>
        {props.contact && <ProfilePreview contact={props.contact} />}
        <CommentContainer />
      </div>
    </div>
    <hr className='block border-gray-200' />
    <div className='flex flex-col h-1/2 space-y-2'>
      <Tab.Group>
        <Tab.List>
          <Tab.Item icon={UserIcon} title='Profile' />
          <Tab.Item icon={DocumentTextIcon} title='Script' />
          <Tab.Item icon={InformationCircleIcon} title='Product Information' />
        </Tab.List>
        <Tab.Panels>
          <Tab.Panel>
            {props.contact && <ProfileDetailed
              contact={props.contact}
              onUpdate={(updatedProfile) => {
                console.log(
                  'AUI:OutboundControlPane:ProfileDetailed:onUpdate',
                  updatedProfile
                );
              }}
            />}
          </Tab.Panel>
          <Tab.Panel>
            <Script />
          </Tab.Panel>
          <Tab.Panel>
            <Information />
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </div>
  </div>
}

export default ControlPanelCampaign;
