import { useEffect, useState } from 'react';
import Header from './components/common/Header';
import {
  Layout,
  TwoPanelContainer
} from './components/containers';
import ContactCenterPane from './components/panes/ContactCenterPane';
import ControlPanelCampaign from './components/panes/ControlPanel/ControlPanelCampaign';
import ControlPanelDefault from './components/panes/ControlPanel/ControlPanelDefault';
import {
  AgentContext,
  ConnectContext,
} from './contexts';

import "amazon-connect-streams";
import { Toaster } from 'react-hot-toast';
import { DispositionCloakProvider } from './contexts/DispositionCloakContext';

let instanceAlias = 'voysus-prod';
let campaignAgentApi = 'https://lvee2gquo2.execute-api.us-east-1.amazonaws.com';
// let campaignAgentApi = 'https://29anpe6qqh.execute-api.us-east-1.amazonaws.com';
let createCallbackTaskApi = 'https://7ce2n3nvfwvinnl5kbds7ydr6y0iietk.lambda-url.ca-central-1.on.aws/';
let inboundAgentApi = 'https://eip6wjo23gmiz565e7ikucej4a0mzhxj.lambda-url.ca-central-1.on.aws/';

declare global {
  interface Window {
    instanceAlias: string;
    campaignAgentApi: string;
    createCallbackTaskApi: string;
    inboundAgentApi: string;
  }
}

window.instanceAlias = instanceAlias;
window.campaignAgentApi = campaignAgentApi;
window.createCallbackTaskApi = createCallbackTaskApi;
window.inboundAgentApi = inboundAgentApi;

// if (window.location.hostname === 'localhost') {
//   window.instanceAlias = 'vtt-prod';
//   window.campaignAgentApi = 'https://29anpe6qqh.execute-api.us-east-1.amazonaws.com';
//   window.createCallbackTaskApi = '';
//   window.inboundAgentApi = '';
// }

function App() {
  const [connectStatus, setConnectStatus] = useState({
    isInitialized: false,
    instanceAlias: window.instanceAlias
  });
  const [agent, setAgent] = useState<connect.Agent | null>(null);
  const [contact, setContact] = useState<connect.Contact | null>(null);

  useEffect(() => {
    connect.core.onInitialized(() => {
      setConnectStatus({
        isInitialized: true,
        instanceAlias: window.instanceAlias
      });
    });
  }, []);

  useEffect(() => {
    connect.agent((agent) => {
      setAgent(agent);

      agent.onRefresh((agent) => {
        setAgent(agent);
      });
    });

    return () => {
      setAgent(null);
    }
  }, []);

  useEffect(() => {
    if (!agent) return;

    function handleBeforeUnload(event: BeforeUnloadEvent) {
      // event.preventDefault();

      if (agent) {
        const states = agent.getAgentStates();
        const offlineState = states
          .filter(state => state.name == "Offline")[0];

        agent.setState(offlineState, {
          success: () => {
            console.debug(
              'AUI:App:handleBeforeUnload: Agent set to offline.', {
              offlineState: offlineState
            });
          },
          failure: (error) => {
            console.error(
              'AUI:App:handleBeforeUnload: Error setting state.', {
              offlineState: offlineState,
              error: error
            });
          }
        });
      }
    }

    // let allowClose = true;

    // const states = agent.getAgentStates();
    // const offlineState = states
    //   .filter(state => state.name == "Offline")[0];
    // const currentState = agent.getState();

    // console.log('AUI:App:handleBeforeUnload: Current State', currentState);
    // console.log('AUI:App:handleBeforeUnload: Offline State', offlineState);

    // if (currentState.name.includes('Offline')) {
    //   console.debug('AUI:App:handleBeforeUnload: Agent is offline.', {
    //     currentState: currentState.name,
    //     offlineState: offlineState.name
    //   });

    //   allowClose = true;
    // } else {
    //   console.debug('AUI:App:handleBeforeUnload: Agent is not offline.', {
    //     currentState: currentState.name,
    //     offlineState: offlineState.name
    //   });

    //   allowClose = false;
    // }


    // if (allowClose === true) {
    //   console.debug('AUI:App:handleBeforeUnload: Allow close.');

    //   window.removeEventListener('beforeunload', handleBeforeUnload);
    // } else {
    //   console.debug('AUI:App:handleBeforeUnload: Prevent close.');

    //   window.addEventListener('beforeunload', handleBeforeUnload);
    // }

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    }
  }, [agent]);

  useEffect(() => {
    connect.contact((contact) => {
      if (contact.getType() === connect.ContactType.VOICE) {
        setContact(contact);

        contact.onDestroy(() => {
          setContact(null);
        });
      }
    });

    return () => {
      setContact(null);
    }
  }, []);

  const attributes = contact?.getAttributes();
  const isCampaignContact = attributes?.['attributes_etcContactListId'] != null;

  console.log('AUI:App:Contact Attributes', attributes);

  return <Layout>
    <DispositionCloakProvider>
      <ConnectContext.Provider value={connectStatus}>
        <AgentContext.Provider value={agent}>
          {/* <ContactContext.Provider value={contact}> */}
          <Header />
          <TwoPanelContainer
            leftPanel={<ContactCenterPane instanceAlias={window.instanceAlias} />}
            rightPanel={isCampaignContact
              ? <ControlPanelCampaign contact={contact} />
              : <ControlPanelDefault contact={contact} />
            }
          />
          {/* </ContactContext.Provider> */}
        </AgentContext.Provider>
      </ConnectContext.Provider>
    </DispositionCloakProvider>
    <Toaster
      position='bottom-center'
      containerStyle={{
        bottom: '3rem',
        background: 'transparent'
      }}
      toastOptions={{
        // duration: 16000,
        style: {
          fontSize: '.875rem',
          border: '1px solid #e5e5e5',
          background: '#fff',
          padding: '.5rem 1.2rem',
        }
      }}
    />
  </Layout>;
}

export default App;
